import construction from "./cat-breading1.jpg";
import "./App.css";

function App() {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10 main-title">
          <h1>Website</h1>
          <h2>under construction</h2>
        </div>
      </div>
      <div
        class="row align-items-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div class="col-sm-10 col-md-6 col-lg-6 p-5">
          <img src={construction} alt="under construction" />
        </div>
      </div>
    </div>
  );
}

export default App;
